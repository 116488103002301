.well {
  margin-bottom: 20px; /* Adjust margin as needed */
  border: 0.1px solid #ccc; /* Border style */
  border-radius: 5px; /* Rounded corners */
  padding: 15px; /* Padding inside the container */
  background-color: #f9f9f9; /* Background color */
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.1); /* Shadow effect */
  }

  .well > p > input {
    background: white !important; /* White background for input elements */
  }

  .well > p {
    padding-top: 5px;
    padding-bottom: 5px;
  }